import "@fontsource/roboto/400.css";
import "@fontsource/roboto/700.css";
import "@fontsource/ubuntu/400.css";
import "tailwindcss/tailwind.css";
import Head from "next/head";
import { AppProps } from "next/app";
import { LayoutComponent, LayoutFunction } from "components/layouts/PageLayout";

function BenIkBijApp({ Component, pageProps }: AppProps): JSX.Element {
  const getLayout: LayoutFunction =
    (Component as LayoutComponent).getLayout ||
    ((page: JSX.Element): JSX.Element => page);
  // Version hash to bust browser caches
  const versionHash = (
    process.env.NEXT_PUBLIC_COMMIT_SHA || "development"
  ).slice(0, 6);
  return (
    <>
      <Head>
        <title>BenIkBij.app</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        {/* BEGIN favicon stuff */}
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`/apple-touch-icon.png?v=${versionHash}`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`/favicon-32x32.png?v=${versionHash}`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`/favicon-16x16.png?v=${versionHash}`}
        />
        <link rel="manifest" href={`/site.webmanifest?v=${versionHash}`} />
        <link
          rel="mask-icon"
          href={`/safari-pinned-tab.svg?v=${versionHash}`}
          color="#f59e0b"
        />
        <link rel="shortcut icon" href={`/favicon.ico?v=${versionHash}`} />
        <meta name="apple-mobile-web-app-title" content="BenIkBij.app" />
        <meta name="application-name" content="BenIkBij.app" />
        <meta name="msapplication-TileColor" content="#ffc40d" />
        <meta name="theme-color" content="#f59e0b" />
        {/* END favicon stuff */}
        {process.env.NEXT_PUBLIC_VERCEL_ENV === "production" && (
          <script
            async
            defer
            data-domain="benikbij.app"
            src="https://plausible.io/js/plausible.js"
          ></script>
        )}
      </Head>
      {getLayout(<Component {...pageProps} />)}
    </>
  );
}

export default BenIkBijApp;
